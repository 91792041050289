@import "../theme/variables.scss";

.assignment-detail-list__container {
    /* margin: 20px; */
    padding: 20px;
    background-color: $color-light;
    height: calc(100vh - 95px);
    /* margin-top: 75px; */
    justify-content: space-between;

    .assignment-detail-list__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .assignment-detail__heading{
            width: 50%;
            display: flex;
            h2{
                margin-left: 20px;
            }
        }

        .assignment-detail__wrapper {
            display: flex;
            justify-content: space-between;
            &>div,
            >span {
                margin: 10px;
                font-size: 20px;
            }
        }
    }

    .assignment-detail-list__wrapper {
        .assignment-detail-action_buttons{
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
            button{
                margin-left: 5px;
            }
        }
    //     .assignment-detail-list__row {
    //         display: flex;
    //         justify-content: space-between;
    //         margin-top: 20px;
    //         margin-bottom: 20px;
    //     }
    }
}