@import './variables.scss';

$theme-colors: (
    "primary": $color-primary,
    "secondary": $color-secondary,
    "light": $color-light,
    "dark": $color-dark,
    "danger": $color-danger,
    "link": $color-link,
    "neutral": $color-neutral);

button{
    border: none;
    height: 40px;
}
input{ padding: 10px;}
input[type="date"]{
  background-color:$color-secondary;
  border-radius: 4px;
  height: 40px;
  border: none;
}
// @import "~bootstrap/scss/bootstrap"