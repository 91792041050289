//Div, Button
@import './variables.scss';
body{
    background-color: $color-secondary;
}
  
h3{
    color: $color-dark;
}
  
textarea{
    width: 100%;
    padding: 5px;
    border-color: $color-secondary;
}

.App {
    text-align: center;
}
    
.btn-primary{
    width: 160px;
    align-items: center;
    background-color: $color-primary;
    color:$color-light;
    border: none; 
    border-radius: 4px;
}

#dropdown-basic-button{
    background-color: $color-secondary;
    color: $color-dark;
    display: flex;
    justify-content: space-between;
}
  