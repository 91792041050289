.comment-list__container{
    height: 65vh;
    overflow: auto;

    .comments__wrapper{
        .comment__row{
            display: flex;
            height: 80px;
            margin: 10px;
            
            span{
                margin-right: 10px;
                img{
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    display: block; /* remove extra space below image */
                }
            }
        }
    }
}

.comment_submit{
    display: flex;
    position: absolute;
    width: calc(100% - 80px); 
    bottom: 40px;
}

.comment_profilePic {
    height: 75px;
    width: 75px;
}
