.assignment-submission-list__wrapper{
    overflow: auto;
    // height: 50vh;
    .assignment-submission-row__wrapper{
        display: flex;
        justify-content: space-between;
        height: 100px;
        margin: 10px;        
        .first_span{
            display: flex;
            justify-content: space-between;
            img{
                height: 60px;
                width: 70px;
                margin-right: 10px;
            }
        }
    }
}