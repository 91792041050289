.nav-links {
    float: right !important;
    padding: 0px;
}

.profile-picture {
    height: 20px;
}

.no-padding-margin {
    margin: 0px !important;
    padding: 0px !important;
}

.icon-buttom-sm {
    padding: 8px !important;
}

.nav-links a:hover {
    color: white;
    text-decoration: none;
}

.icon-button {
    background-color: #1a386d;
    border-radius: 50%;
    padding: 12px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-inline--fa {
        position: relative;
        left: 50%;
        transform: translateX(-8px);
        font-size: 22px;
        padding: 0px !important;
        margin: 0px !important;
        text-align: center;
        color: white !important;
    }
}

.user-picture {
    width: 40px;
    height: 40px;
    padding: 2px;
}

nav {
    position: relative;
    margin-bottom: 10px;

    .nav-links {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 0px !important;
        margin: 0px !important;

        li {
            display: flex;
            align-items: center;
            justify-content: center;

            h3 {
                color: white;
                padding: 0px 5px;
            }
        }
    }
}

.language-dropdown {
    margin-right: 8px;

    select {
        height: 68% !important;
    }
}