
@import "./theme/custom.scss";
@import "./theme/variables.scss";
@import "./theme/common.scss";

/*Card for Login Signup forgetPassword*/
.cardsize{
  margin: 0 auto;
  width: 40%;
  margin-top: 10vh;
  text-align: center;
}

/*NavBar: Header Component*/
nav{
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  background-color: $header-background;
  height: 55px;
}

/*Nav Links: Queno Logo and Logout*/
.nav-links{
  display: flex;
  font-size: 20px;
  list-style: none;
  padding: 10px;
  margin: 10px;
  color: $color-light;
//  overflow: hidden;
//  white-space: nowrap;
//  text-overflow: ellipsis;
}
.nav-links a{
  color: $color-light;
}
nav img{
  margin-left: 20px;
}
